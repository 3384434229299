import SEO from "../components/seo";
import * as React from "react";
import {Container} from "../components/Container";
import {Section} from "../components/Section";

import "../main.scss"

export default function Home() {
    return <div>
        <SEO
            title={"Solo Break! Privacy Policy"}
    description={"Solo break privacy policy"}
    />

        <Container>
            <Section>
                <div className="content">
                    <h1>Privacy Policy</h1>

                    <h2>
                    Overview
                    </h2>

                    <p>This Privacy Policy describes how your personal information is handled in Solo Break! for iOS.</p>

                    <p>We Collect No Personal Information Using Our Applications</p>
                    <p>We do not collect, use, save, or have access to any of your personal data recorded Solo Break! for iOS.</p>

                    <p>Individual settings relating to the app are not personal and are stored only on your device. We don’t process that information at all and have no access to it.</p>

                    <h2>Our Applications Comply with the Children’s Online Privacy Protection Act</h2>
                    Our applications don’t collect personal information from anyone, including children under the age of 13.

                    <h2>Contact Us</h2>
                    If you have any questions about this Privacy Policy, feel free to contact me at larry@mail.dragondrummer.com
                </div>
            </Section>
        </Container>

    </div>
}